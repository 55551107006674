import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import ItemImage from '../itemImage'

class Item extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hide: this.props.item.hide || true
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    e.preventDefault()
    this.setState({
      hide: !this.state.hide
    })
  }

  render () {
    let allData = false
    let linkElement = false
    const item = this.props.item

    allData = (
      <p dangerouslySetInnerHTML={{
        __html: item.shortText || item.description || item.node.excerpt
      }}/>
    )

    if (item.overviewURL || item.slug) {
      linkElement = (
        <p><Link className="blocklist__link" to={item.overviewURL || item.slug}>
          {item.overviewURLTextLong || 'Read the blog'}
        </Link></p>
      )
    }

    return (
      <div className={this.state.hide ? 'blocklist__item blocklist__item-hide' : 'blocklist__item'}>
        <div className="blocklist__wrapper">
          <div className="blocklist__image">
            <div className="blocklist__image-image">
              <ItemImage item={item}/>
            </div>
            <h2>{item.title}</h2>
            <p className='blocklist__tag'>{(item.subType || item.mainType || 'Project').toUpperCase()}</p>
          </div>
          <div className='blocklist__button' onClick={this.handleClick}></div>
          <div className="blocklist__text">
            <div className="blocklist__text-wrapper">
              {allData}
              {linkElement}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Item.defaultProps = {
  item: {
    title: 'WIP',
    description: 'TBA',
    overviewImg: 'flint.png',
    overviewImgSize: { h: 3, w: 4 }
  }
}

Item.propTypes = {
  item: PropTypes.object.isRequired
}

export default Item
