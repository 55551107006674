import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../../components/Layout/layout-single'
import ProcessBlogs from '../../components/datacontent'
import SEO from '../../components/SEO/seo'
import List from '../../components/Blocklist/list'
import Bloglist from '../../components/Bloglist/list'
import Header from '../../components/Header/header'

class SecondPage extends React.Component {
  render () {
    const data = this.props.data
    const allItems = ProcessBlogs(data.cases.edges, data.projectcases.edges)
    const projectsTag = ProcessBlogs(data.tagprojects.edges, [], allItems)

    let postsElementsFirst, postsElementsRest
    if (allItems.length > 2) {
      postsElementsFirst = allItems.slice(0, 2)
      postsElementsRest = allItems.slice(2)
    } else {
      postsElementsFirst = allItems
    }

    return (
      <Layout>
        <>
          <SEO title="Design process" imagefile="all" location={this.props.location} />
          <Header
            location={this.props.location}
            fluid={data.file.childImageSharp.fluid}
            alt="Exploring the design process"
            title="Exploring the design process"
          />
        </>
        <>
          <h1>Exploring the design process</h1>
          <p>You-et's focus is on how designers achieve their goals and how to improve this. The design process is a non-linear iterative process of diverging and converging. Although it is circular, different phases can be represented in a general structure. Here, I've collected interactive and/or readworthy pieces about the design process, models and methods.</p>
          <ul className="paragraph-buttons">
            <li><Link to="/project">Design cases</Link></li>
            <li><a className="paragraph-button__button-active">The design process</a></li>
            <li><Link to="/blog">Blog</Link></li>
          </ul>
          <Bloglist posts={postsElementsFirst} size="big" />
          {postsElementsRest && <Bloglist posts={postsElementsRest.slice(0, 5)} />}
          <h2>Applied methods</h2>
          <List items={projectsTag} size="medium" />
          <ul className="paragraph-buttons">
            <li><Link to="/project">Design cases</Link></li>
            <li><a className="paragraph-button__button-active">The design process</a></li>
            <li><Link to="/blog">Blog</Link></li>
          </ul>
        </>
      </Layout>
    )
  }
}

export default SecondPage

// filter: {frontmatter: {mainType: {in: ["case", "interactive"]}}}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    tagprojects: allProjectsJson(
      sort: { fields: [dateOrder], order: DESC },
      filter: {tags: {in: [25]}},
    ) {
      edges {
        node {
          ...ProjectNodes
        }
      }
    }
    projectcases: allProjectsJson(
      sort: { fields: [dateOrder], order: DESC },
      filter: {mainType: {in: ["interactive"]}},
    ) {
      edges {
        node {
          ...ProjectNodes
        }
      }
    }
    cases: allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {fileAbsolutePath: {regex: "/blog/(placeholder/)*design-process.*md$/"}}

    ) {
      edges {
        node {
          ...BlogNodes
        }
      }
    }
    readmore: allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {mainType: {in: ["blog"]}}},
      limit: 3
    ) {
      edges {
        node {
          ...BlogNodes
        }
      }
    }
    file(relativePath: { eq: "overview/lucas-benjamin-wQLAGv4_OYs-unsplash.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
