import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

import Item from './item'
import './list.scss'

class List extends React.Component {
  render () {
    const size = this.props.size || 'normal'
    const listItems = this.props.items.map((item) =>
      <Item item={item} key={`key-list-item-${item.id}`}/>
    )

    let button = false
    if (this.props.buttonLink) {
      button = (<Link className="row__button" to={this.props.buttonLink}>{this.props.buttonText}</Link>)
    }

    return (
      <div className={`blocklist blocklist--${size}`}>
        {this.props.title && (<h1 className="blocklist__title">{this.props.title}</h1>) }
        <div className="blocklist__list">
          {listItems}
        </div>
        {button}
      </div>
    )
  }
}

List.defaultProps = {
  items: []
}

List.propTypes = {
  items: PropTypes.array
}

export default List
